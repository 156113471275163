import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangeHistoryDataTable = _resolveComponent("ChangeHistoryDataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_ChangeHistoryDataTable, {
          items: _ctx.getCustomerMap[_ctx.index as any].customer.change_date_items,
          onAfterFilter: _ctx.handleFilter
        }, null, 8, ["items", "onAfterFilter"])
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}